.TopJoinerContainer {
    width : 100%;
    height: calc(100vh - 230px)
}

.TopJoinerTimeToEnd {
    width          : 100%;
    display        : flex;
    flex-direction : column;
    align-items    : center;
    justify-content: center
}

.TopJoinerTimeToEndTitle {
    font-weight: 400;
    font-family: Product Sans !important;
    font-size  : 14px;
    color      : #616161
}

.TopJoinerTimes {
    width          : 100%;
    display        : flex;
    flex-direction : row;
    justify-content: center;
    align-items    : start;
    margin-top     : 8px
}

.TopJoinerTimesItem {
    display        : flex;
    flex-direction : column;
    justify-content: center;
    align-items    : center
}

.TopJoinerTimesNumber {
    font-weight: 700;
    font-family: Product Sans !important;
    font-size  : 24px;
    color      : #212121
}

.TopJoinerTimesString {
    font-weight: 400;
    font-family: Product Sans !important;
    font-size  : 10px;
    color      : #616161
}

.Wdot {
    font-weight: 700;
    font-family: Product Sans !important;
    font-size  : 24px;
    color      : #212121;
    margin     : 0 8px
}

.TopJoinerImagesContainer {
    width         : 94%;
    margin        : auto;
    padding-bottom: 36px
}

@media (min-width:768px) {
    .TopJoinerDetails {
        width          : 100%;
        display        : flex;
        flex-direction : row;
        justify-content: center;
        align-items    : center;
        margin         : auto;
        margin-top     : 32px;
        margin-bottom  : 24px
    }
}

@media (max-width:768px) {
    .TopJoinerDetails {
        width          : 100%;
        display        : flex;
        flex-direction : row;
        justify-content: space-between;
        align-items    : center;
        margin         : auto;
        margin-top     : 32px;
        margin-bottom  : 24px
    }
}

.TopJoinerDetailItem {
    display        : flex;
    flex-direction : column;
    justify-content: center;
    align-items    : center;
    width          : 25%
}

.TopJoinerDetailName {
    font-weight: 400;
    font-family: Product Sans !important;
    font-size  : 14px;
    color      : #212121;
    text-align : center
}

.MyLeagueRow4ItemCoulmn1 {
    font-weight: 700;
    font-family: Product Sans !important;
    font-size  : 14px;
    color      : #212121;
    display    : flex
}

.TopJoinerDetailJoins {
    font-weight: 400;
    font-family: Product Sans !important;
    font-size  : 12px;
    color      : #212121;
    margin-top : 12px;
    margin-bottom : 4px;
    display: flex;
    flex-direction: row;
    
}

.TopJoinerDetailGift {
    display        : flex;
    flex-direction : row;
    justify-content: center;
    align-items    : center
}

.TopJoinerDetailGiftInner {
    display       : flex;
    flex-direction: row;
    align-items   : center
}

.TopJoinerDetailGiftInnerItems {
    display       : flex;
    flex-direction: column
}

.TopJoinerDetailGiftImg {
    margin-right: 5px
}

.TopJoinerDetailGiftContImg {
    margin-right  : 2px;
    display       : flex;
    flex-direction: row;
    align-items   : center
}

.TopJoinerDetailGiftInnerItemsCoin {
    font-weight   : 700;
    font-family   : Product Sans !important;
    font-size     : 14px;
    color         : #212121;
    display       : flex;
    flex-direction: row;
    align-items   : center
}

.TopJoinerDetailGiftInnerItemsCoinImg {
    margin-left: 4px
}

.TopJoinerDetailGiftInnerItemsxpImg {
    margin-left: 4px
}

.TopJoinerDetailGiftInnerItemsxp {
    font-weight   : 700;
    font-family   : Product Sans !important;
    font-size     : 14px;
    color         : #212121;
    margin-top    : 3px;
    display       : flex;
    flex-direction: row;
    align-items   : center
}

.UserImageTopJoinerDetailImage {
    height: 130px
}

.UserImageTopJoinerDetailImage2 {
    height: 116px
}

.TopJoinerDetailImage {
    position       : relative;
    display        : flex;
    flex-direction : row;
    justify-content: center;
    margin-bottom  : 8px
}

.TopJoinerDetailImageUserInnerImage {
    position     : absolute;
    top          : 7px;
    border-radius: 40px
}

.TopJoinerDetailImageUserInnerImage2 {
    position     : absolute;
    top          : 8px;
    border-radius: 33px;
    width        : 65px;
    height       : 65px
}

.ShowFirstLetter {
    position       : absolute;
    top            : 6px;
    border-radius  : 33px;
    width          : 67px;
    height         : 69px;
    background     : #E0E0E0;
    color          : #212121;
    font-family    : Product Sans !important;
    font-size      : 34px;
    font-weight    : 500;
    display        : flex;
    flex-direction : row;
    justify-content: center;
    align-items    : center
}

.ShowFirstLetter1 {
    position       : absolute;
    top            : 8px;
    border-radius  : 33px;
    width          : 72px;
    height         : 74px;
    background     : #FBF2B9;
    color          : #564500;
    font-family    : Product Sans !important;
    font-size      : 34px;
    font-weight    : 500;
    display        : flex;
    flex-direction : row;
    justify-content: center;
    align-items    : center
}

.ShowFirstLetter3 {
    position       : absolute;
    top            : 6px;
    border-radius  : 33px;
    width          : 67px;
    height         : 69px;
    background     : #FEE2C0;
    color          : #321203;
    font-family    : Product Sans !important;
    font-size      : 34px;
    font-weight    : 500;
    display        : flex;
    flex-direction : row;
    justify-content: center;
    align-items    : center
}

.TopJoinerDetailImageUserInnerImage {
    width : 75px;
    height: 75px
}

.MyLeagueRow4ItemCoulmn {
    display        : flex;
    flex-direction : column;
    justify-content: center;
    align-items    : start
}

.MyLeagueRow4ItemInner {
    display        : flex;
    flex-direction : row;
    justify-content: flex-start;
    align-items    : center
}

.MyLeagueRow4ItemCoulmn2 {
    color      : #616161;
    font-family: Product Sans !important;
    font-size  : 12px;
    font-weight: 400;
    margin-top : 4px
}

.YouBadge {
    color        : #616161;
    font-family  : Product Sans !important;
    font-size    : 12px;
    font-weight  : 400;
    background   : #E0E0E0;
    padding      : 1px 6px;
    border-radius: 8px;
    margin-left  : 8px
}
.PreviousLeagueLink{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: #1976D2;
    font-family  : Product Sans !important;
    font-size    : 14px;
    font-weight  : 500;
    padding-bottom: 20px;
}
.PreviousLeagueLink img{
    margin-right: 4px;
}

.ImageProfileUserTopUser{
    margin-right: 12px;
    width: 32px;
    height: 32px;
    border-radius: 33px;
}
@media (min-width:980px) {
    .MyLeagueRow4ItemsTopJoiner {
        width                     : 100%;
        /* border-bottom-left-radius : 10px;
        border-bottom-right-radius: 10px; */
        padding-top               : 1px;
        height                    : fit-content;
        border                    : 2px solid red;
        min-height                : 50dvh;
        border-radius             : 11px
    }
    .MyLeagueRow4ItemsTopJoinerInner{
        /* width                     : 100%; */
        /* border-bottom-left-radius : 10px;
        border-bottom-right-radius: 10px; */
        padding-top: 19px;
        margin               : 1px;
        height                    : fit-content;
        border                    : 2px solid #2B389F;
        min-height                : 50dvh;
        border-radius             : 11px
    }
}

@media (max-width:980px) {
    .MyLeagueRow4ItemsTopJoiner {
        width                     : 100%;
        /* border-bottom-left-radius : 10px;
        border-bottom-right-radius: 10px; */
        padding-top               : 1px;
        height                    : fit-content;
        border                    : 2px solid  red;
        min-height                : 50dvh;
        border-radius             : 11px
    }
    .MyLeagueRow4ItemsTopJoinerInner {
        /* width                     : 100%; */
        /* border-bottom-left-radius : 10px;
        border-bottom-right-radius: 10px; */
        padding-top: 19px;

        margin               : 1px;
        height                    : fit-content;
        border                    : 2px solid  #2B389F;
        min-height                : 50dvh;
        border-radius             : 11px
    }
}