.LogOutContainer {
    width    : 100%;
    max-width: 768px;
    margin   : auto;
    height   : 100vh;


}

.LogOutBody {
    margin-top     : 32px;
    width          : 91%;
    max-width      : 768px;
    margin         : auto;
    overflow       : hidden;
    display        : flex;
    flex-direction : column;
    justify-content: center;
    align-items    : start;
    margin-top: 72px;
}

.LogOutFlexItem {
    display        : flex;
    flex-direction : row;
    justify-content: start;
    align-items    : center;
    width          : 100%;
    height         : 72px;
    padding: 0 4%;
    cursor         : pointer
}
@media (min-width: 980px) {
    .LogOutFlexItem:hover{
     cursor: pointer;
     background: #F5F5F5;
    }
  
   }
.LogOutFlexItemDisable {
    opacity        : .5;
    color          : #757575;
    display        : flex;
    flex-direction : row;
    justify-content: start;
    align-items    : center;
    padding: 0 4%;

}

.LogOutInnerItemDisable {
    display        : flex;
    flex-direction : column;
    justify-content: center;
    align-items    : baseline;
    margin-left: 24px;
    color: #757575;
}

.LogOutInnerItem {
    display        : flex;
    flex-direction : column;
    justify-content: center;
    align-items    : start;
    margin-left    : 24px;
}

.LogOutTextInner1 {
    font-size  : 14px;
    font-family: Product Sans;
    font-weight: 400;
    color      : #212121;
}

.LogOutTextInner2 {
    font-size  : 12px;
    font-family: Product Sans;
    font-weight: 400;
    color      : #616161;
    margin-top : 8px;
}

.LogOutIcons {
    color: #1976D2;
}

.LogOutIconsRed {
    color: #D32F2F;
}
.LogOutIconsGrey {
    color: #757575;
}