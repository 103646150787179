.VerificationCode {
    width    : 86.6%;
    max-width: 796px;
    margin   : auto auto 2rem;
    height   : 100vh;
    position : relative
}

.VerificationCodeHeader {
    width          : 100%;
    height         : 56px;
    display        : flex;
    flex-direction : row;
    justify-content: flex-start;
    align-items    : center
}

.BackIconVerificationCode {
    margin-right   : 16px;
    display        : flex;
    flex-direction : row;
    align-items    : center;
    justify-content: center
}

.BackIconVerificationCodeText {
    color      : #212121;
    font-size  : 20px;
    font-weight: 500;
    font-family: Product Sans
}

.VerificationCodeBody {
    width     : 100%;
    margin-top: 24px
}

.VerificationCodeBodyText {
    color             : #212121;
    font-size         : 14px;
    width             : 100%;
    flex-direction    : row;
    justify-content   : center;
    align-items       : center;
    height            : 56px;
    /* border         : 1px solid #e0e0e0; */
    flex-direction    : row;
    justify-content   : space-between;
    align-items       : center;
}

.ErrorIcon {
    color       : #fff;
    margin-right: 16px
}
.RegisterPasswordTextField123 {
    width     : 100%;
    margin-top: 2px !important;
    height    : 56px !important
}

