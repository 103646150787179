.DialogeContainer {
    width    : 100%;
    height   : 100vw;
    max-width: 768px;
    margin:0 auto;

}

.HeaderDialoge {
    width : 100%;
    height: 48px;
}

.HeaderDialoge button {
    margin: 16px 0 0 16px;
}

.DialogeBody {
    width          : 100%;
    display        : flex;
    flex-direction : column;
    justify-content: center;
    align-items    : center;
    height: 90vh;
}