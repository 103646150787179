.MemberPackageItem {
    width          : 100%;
    display        : flex;
    flex-direction : row;
    align-items    : center;
    justify-content: space-between;
    height         : 72px;
    text-decoration: none
}

.MemberItem {
    display        : flex;
    flex-direction : row;
    justify-content: center;
    align-items    : center
}

.MemberItemDigit {
    display        : flex;
    flex-direction : column;
    justify-content: center;
    align-items    : center;
    margin-left    : 16px;
    height         : 44px
}

.MemberItemDigitNewPrice {
    font-size  : 16px;
    font-weight: 700;
    color      : #212121
}

.MemberItemDigitOldPrice {
    font-size      : 14px;
    font-weight    : 400;
    margin-top     : 8px;
    text-decoration: line-through;
    color          : #757575
}

.MemberItemOffDigit {
    width        : fit-content;
    height       : fit-content;
    font-size    : 12px;
    font-weight  : 400;
    color        : #C62828;
    background   : #FFEBEE;
    padding      : 5px;
    border-radius: 30px;
    margin-right : 9px
}

.MemberItemOrderButton {
    font-size    : 14px;
    font-weight  : 500;
    color        : #1565C0;
    border       : 1px solid #BDBDBD;
    padding      : 10px 16px;
    background   : #fff;
    border-radius: 30px;
    cursor       : pointer;
    font-family  : Product Sans
}

@media (min-width:980px) {
    .MemberItemOrderButton:hover {
        background: #E3F2FD;
        cursor    : pointer
    }
}

.MemberItemOrderOldPrice {
    color          : #757575;
    font-size      : 12px;
    font-weight    : 400;
    text-align     : center;
    margin-top     : 4px;
    text-decoration: line-through
}

.SomthingWentWrong {
    width          : 100%;
    display        : flex;
    flex-direction : column;
    justify-content: center;
    align-items    : center
}

.SomthingWentWrongButton {
    background   : #1976D2;
    border-radius: 40px;
    outline      : none;
    border       : none;
    color        : #fff;
    font-family  : Product Sans;
    font-size    : 14px;
    font-weight  : 500;
    padding      : 12px;
    margin-top   : 32px
}

.SomthingWentWrongText {
    font-family: Product Sans;
    font-size  : 14px;
    font-weight: 400;
    margin-top : 24px
}
.XpCountInlist{
   padding: 1px 4px;
   background: #CDE9FF; 
   font-family  : Product Sans;
   font-size    : 12px;
   font-weight  : 400;
   margin-left: 8px;
   border-radius: 33px;
   
}
.XpCountInlistIcon{
    width: 10px;
    height: 10px;
    margin-bottom: -1px;
}