.AccountList1 {
    width          : 95%;
    max-width      : 768px;
    margin         : auto;
    position       : relative;
    display        : flex;
    flex-direction : column;
    justify-content: space-between;
    overflow       : scroll;
    margin-top     : 56px;
    align-items    : end
}

.AccountListContainer {
    width          : 95%;
    max-width      : 768px;
    display        : flex;
    flex-direction : column;
    justify-content: flex-start;
    align-items    : center;
    margin         : auto;
    height         : 100%;
    padding-bottom : 5rem
}

.AccountListHeader {
    width          : 95%;
    display        : flex;
    flex-direction : row;
    justify-content: space-between;
    align-items    : center;
    height         : 40px;
    margin         : 24px 0 9px 0
}

.AccountListHeaderItem1 {
    font-size     : 14px;
    font-weight   : 400;
    font-family   : Product Sans;
    color         : #212121;
    display       : flex;
    flex-direction: row;
    align-items   : center
}

.AccountListHeaderItem1Inner {
    font-size     : 16px;
    font-weight   : 700;
    font-family   : Product Sans;
    color         : #212121;
    display       : flex;
    flex-direction: row;
    align-items   : center
}

.AccountListHeaderItem2 {
    font-size    : 14px;
    font-weight  : 500;
    font-family  : Product Sans;
    color        : #1976D2;
    cursor       : pointer;
    border-radius: 53px;
    width        : 100%
}

@media (min-width:980px) {
    .AccountListHeaderItem2:hover {}

    .AddAccountAbsoluteButtonInner:hover {
        background: #1565C0
    }

    .AccountListItemRemove:hover {
        background: #E0E0E0
    }
}

.AccountListItemActive {
    width          : 100%;
    display        : flex;
    flex-direction : row;
    justify-content: center;
    align-items    : center;
    background     : #E3F2FD;
    margin-bottom  : 16px;
    border-radius  : 16px;
    height         : 72px;
    animation      : fadeIn .8s ease forwards;
    /* position       : relative; */
}

.AnimationContainer {
    width: 100%;
    height: 40%;
    position: absolute;
}

@media only screen and (max-width:600px) {

    .AnimationXpList {
        width             : 10px;
        height            : 10px;
        position          : absolute;
        /* animation         : horizontalVibration 4s ease-in-out infinite; */
        animation      : horizontalVibration 4s ease-in-out 1;
        right              : 40%;
        top               : 50%;

        display           : flex;
        flex-direction    : row;
        justify-content   : center;
        align-items       : center;
        font-family       : Product Sans;
        font-size         : 12px;
        font-style        : normal;
        font-weight       : 700;
        color             : #212121;
        visibility        : hidden;

    }

    .AnimationCoinList {
        width             : 10px;
        height            : 10px;
        position          : absolute;
        /* animation         : horizontalVibration 4s ease-in-out infinite; */
        animation      : horizontalVibration 4s ease-in-out 1;
        right             : 20%;
        top               : 50%;

        display           : flex;
        flex-direction    : row;
        justify-content   : center;
        align-items       : center;
        font-family       : Product Sans;
        font-size         : 12px;
        font-style        : normal;
        font-weight       : 700;
        color             : #212121;
        visibility        : hidden;

    }
}

@media only screen and (min-width:600px) {
    .AnimationXpList {
        width                : 10px;
        height               : 10px;
        position             : absolute;
        /* animation            : horizontalVibration1 4s ease-in-out infinite; */
        animation         : horizontalVibration 4s ease-in-out 1;
        left                 : 35%;
        top               : 50%;
        /* bottom: 0; */

        display              : flex;
        flex-direction       : row;
        justify-content      : center;
        align-items          : center;
        font-family          : Product Sans;
        font-size            : 12px;
        font-style           : normal;
        font-weight          : 700;
        color                : #212121;
        visibility           : hidden;

    }

    .AnimationCoinList {
        width                : 10px;
        height               : 10px;
        position             : absolute;
        /* animation            : horizontalVibration1 4s ease-in-out infinite; */
        animation         : horizontalVibration 4s ease-in-out 1;
        right                : 35%;
        top               : 50%;
        /* bottom: 0; */
        display              : flex;
        flex-direction       : row;
        justify-content      : center;
        align-items          : center;
        font-family          : Product Sans;
        font-size            : 12px;
        font-style           : normal;
        font-weight          : 700;
        color                : #212121;
        visibility           : hidden;
    }
}


@keyframes horizontalVibration1 {


    0% {
        visibility: visible;
        top         : 50%;
        /* transform: translateX(20); */
    }

    100% {
        /* transform: translateX(5px); */
        top: 25%;
        visibility           : hidden;
       
    }
}





@keyframes fadeIn {
    from {
        opacity: 0
    }

    to {
        opacity: 1
    }
}

.AccountListItemActiveItem1 {
    width          : 90%;
    display        : flex;
    flex-direction : row;
    justify-content: flex-start;
    align-items    : center;


}

.AccountListItemActiveImageContainer {
    position       : relative;
    width          : 48px;
    height         : 48px;
    display        : flex;
    flex-direction : row;
    justify-content: center;
    align-items    : center;
    margin-left    : 16px
}

.AccountListItemActiveImage {
    width        : 48px;
    height       : 48px;
    border-radius: 100%
}

.AccountListItemActiveImageJoining {
    width        : 32px;
    height       : 32px;
    border-radius: 100%
}

.AccountListItemActiveImageJoiningLoader {
    width                            : 48px;
    height                           : 48px;
    margin                           : 0 auto 0;
    border                           : solid 2px #1976d2;
    border-radius                    : 50%;
    border-right-color               : #fff0;
    border-bottom-color              : #fff0;
    -webkit-transition               : all 0.5s ease-in;
    -webkit-animation-name           : rotate;
    -webkit-animation-duration       : 1.0s;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    transition                       : all 0.5s ease-in;
    animation-name                   : rotate;
    animation-duration               : 1.0s;
    animation-iteration-count        : infinite;
    animation-timing-function        : linear;
    position                         : absolute
}

.FlagPhoneNumber {
    width       : 16px;
    height      : 16px;
    margin-right: 4px
}

.AccountListItemActiveNamePhone {
    margin-left    : 16px;
    display        : flex;
    flex-direction : column;
    justify-content: center;
    align-items    : center
}

.AccountListItemArrowRight {
    color       : #1565C0;
    width       : 16px !important;
    height      : 16px !important;
    margin-right: 16px
}

.AccountListItemActiveItem2 {
    width          : 10%;
    display        : flex;
    flex-direction : row;
    justify-content: flex-end;
    align-items    : center
}

.AccountListItemActiveName {
    color      : #212121;
    font-size  : 16px;
    font-weight: 400;
    font-family: Product Sans;
    text-align : left;
    width      : 100%
}

.AccountListItemActivePhone {
    font-size      : 14px;
    font-weight    : 400;
    font-family    : Product Sans;
    color          : #616161;
    margin-top     : 8px;
    text-align     : left;
    width          : 100%;
    display        : flex;
    flex-direction : row;
    justify-content: flex-start;
    align-items    : center
}

.AddAccountAbsoluteButton {
    position : fixed;
    bottom   : 96px;
    width    : 90%;
    max-width: 768px;
    z-index  : 0
}

.AddAccountAbsoluteButtonInnerContainer {
    width          : 100%;
    display        : flex;
    flex-direction : row;
    justify-content: flex-end;
    z-index        : 0;
    align-items    : center
}

.AddAccountAbsoluteButtonInner {
    width          : 56px;
    border-radius  : 16px;
    background     : #1976D2;
    height         : 56px;
    display        : flex;
    flex-direction : row;
    justify-content: center;
    align-items    : center;
    color          : #fff;
    position       : fixed;
    bottom         : 96px;
    box-shadow     : 0 4px 8.699999809265137px 0 #00000040
}

.AddIcon {
    color : #fff;
    height: 24px;
    width : 24px
}

.RestrictSpan {
    background   : #757575;
    color        : #fff;
    border-radius: 20px;
    padding      : 3px 4px;
    font-size    : 12px;
    font-weight  : 400;
    margin-left  : 8px
}

.AccountListItemDEActive {
    width          : 100%;
    display        : flex;
    flex-direction : row;
    justify-content: center;
    align-items    : center;
    background     : #F5F5F5;
    margin-bottom  : 16px;
    border-radius  : 16px;
    height         : 72px
}

.AccountListItemRemove {
    border       : 1px solid #E0E0E0;
    color        : #1976D2;
    border-radius: 36px;
    margin-right : 16px;
    cursor       : pointer;
    padding      : 12px 16px;
    font-size    : 14px;
    font-weight  : 500;
    font-family  : Product Sans
}

.WifiIcon {
    color       : #fff;
    margin-right: 16px
}

.AddAccountAndAutoJoinInList {
    width          : 100%;
    display        : flex;
    flex-direction : row;
    justify-content: space-around;
    align-items    : center;
    background     : #fff;
    height         : 96px;
    color          : #fff;
    position       : fixed;
    bottom         : 79px;
    right          : 0;
    left           : 0;
    box-shadow     : 0 2px 15.7px 0 #00000040;
    margin         : auto !important
}

@media (min-width:768px) {
    .AddAccountAndAutoJoinInListInner {
        width          : 100%;
        display        : flex;
        flex-direction : row;
        justify-content: space-around;
        align-items    : center;
        margin         : auto !important;
        max-width      : 768px
    }

    .StopListButton2 {
        display        : flex;
        flex-direction : row;
        justify-content: center;
        align-items    : center;
        color          : #D32F2F;
        background     : #fff;
        border         : 1px solid #BDBDBD;
        border-radius  : 24px;
        padding        : 12px 14px;
        cursor         : pointer;
        position       : relative;
        font-size      : 16px;
        font-weight    : 500;
        font-family    : Product Sans
    }

    .AddAccoontButton2 {
        display        : flex;
        flex-direction : row;
        justify-content: center;
        align-items    : center;
        color          : #1976D2;
        background     : #fff;
        border         : 1px solid #BDBDBD;
        border-radius  : 24px;
        padding        : 12px 14px;
        cursor         : pointer;
        position       : relative;
        width          : 50%;
        font-size      : 16px;
        font-weight    : 500;
        font-family    : Product Sans;
        margin-right   : 16px
    }

    .AddAccoontButton2:hover {
        background: #F5F5F5
    }

    .StopListButton2:hover {
        background: #F5F5F5
    }

    .AutoJoinListButton2:hover {
        background: #1565C0
    }

    .AutoJoinListButton2Container {
        width          : 50%;
        display        : flex;
        flex-direction : row;
        justify-content: center;
        align-items    : center
    }
}

@media (max-width:768px) {
    .AddAccountAndAutoJoinInListInner {
        width          : 92%;
        display        : flex;
        flex-direction : row;
        justify-content: space-around;
        align-items    : center;
        margin         : auto !important;
        max-width      : 768px
    }

    .AddAccoontButton2 {
        display        : flex;
        flex-direction : row;
        justify-content: center;
        align-items    : center;
        color          : #1976D2;
        background     : #fff;
        border         : 1px solid #BDBDBD;
        border-radius  : 24px;
        padding        : 12px 14px;
        cursor         : pointer;
        position       : relative;
        width          : 40%;
        font-size      : 16px;
        font-weight    : 500;
        font-family    : Product Sans;
        padding        : 12px 18px;
        margin-right   : 16px
    }

    .AutoJoinListButton2Container {
        width          : 50%;
        display        : flex;
        flex-direction : row;
        justify-content: center;
        align-items    : center
    }

    .StopListButton2 {
        display        : flex;
        flex-direction : row;
        justify-content: center;
        align-items    : center;
        color          : #D32F2F;
        background     : #fff;
        border         : 1px solid #BDBDBD;
        border-radius  : 24px;
        padding        : 12px 14px;
        cursor         : pointer;
        position       : relative;
        width          : 83%;
        font-size      : 16px;
        font-weight    : 500;
        font-family    : Product Sans
    }
}

.AutoJoinListButton2 {
    display        : flex;
    flex-direction : row;
    justify-content: center;
    align-items    : center;
    color          : #fff;
    background     : #1976D2;
    border         : 1px solid #1976D2;
    border-radius  : 24px;
    padding        : 14px 21px;
    cursor         : pointer;
    width          : 100%;
    font-size      : 16px;
    font-weight    : 500;
    font-family    : Product Sans
}

.AutoJoinListButton2disable {
    display        : flex;
    flex-direction : row;
    justify-content: center;
    align-items    : center;
    color          : #616161;
    background     : #E0E0E0;
    border         : 1px solid #E0E0E0;
    border-radius  : 24px;
    padding        : 14px 21px;
    cursor         : pointer;
    width          : 100%;
    font-size      : 16px;
    font-weight    : 500;
    font-family    : Product Sans;
    cursor         : not-allowed
}

.plus15 {
    position     : absolute;
    top          : -7px;
    right        : 0;
    color        : #212121;
    border-radius: 33px;
    padding      : 0 4px;
    background   : linear-gradient(90deg, #FFC235 21.26%, #FFD485 60%, #FFE4A3 50%);
    display      : flex;
}

.plus15Coin {
    /* background: #FFC235; */
    font-size  : 12px;
    font-weight: 700;
    font-family: Product Sans;
    display    : flex;
}

.plus15XP {
    font-size  : 12px;
    font-weight: 700;
    font-family: Product Sans;
    display    : flex;

}

.PlusPlus {
    font-size  : 12px;
    font-weight: 700;
    font-family: Product Sans;
}

.AutoJoinListButton2 img {
    margin-right: 4px
}

.StopListButton2 img {
    margin-right: 4px
}

.AddAccoontButton2 img {
    margin-right: 4px
}

.NewUserBottomSheet {
    width          : 100%;
    max-width      : 768px;
    margin         : auto;
    display        : flex;
    flex-direction : column;
    justify-content: center;
    align-items    : center;

}

.NewUserBottomSheetTitle {
    width      : 100%;
    text-align : center;
    color      : #212121;
    font-size  : 20px;
    font-weight: 500;
    font-family: Product Sans;
    margin-top : 36px;
}

.NewUserBottomSheetLottie {
    width          : 100%;
    margin-top     : 24px;
    display        : flex;
    flex-direction : row;
    justify-content: center;
    align-items    : center;
}

.NewUserBottomSheetReward {
    width          : 100%;
    margin-top     : 24px;
    display        : flex;
    flex-direction : row;
    justify-content: center;
    align-items    : center;
    font-size      : 20px;
    font-weight    : 700;
    font-family    : Product Sans;
}

.ParentNewUserBShButton {
    width        : 100%;
    margin-top   : 36px;
    margin-bottom: 17px;
}

.NewUserBShButton {
    background   : #1976D2;
    color        : #FFFFFF;
    font-size    : 16px;
    font-weight  : 500;
    font-family  : Product Sans;
    width        : 100%;
    height       : 48px;
    border       : none;
    border-radius: 33px;
    outline      : none;
    cursor       : pointer;
}

.NewUserBottomSheetIcon {
    width      : 20px;
    height     : 20px;
    margin-left: 4px;
}