.MyLeagueComponent {
    width: 100%
}

.MyLeague {
    display       : flex;
    flex-direction: column;
    width         : 95%;
    margin        : auto;
    padding-bottom: 56px
}

.MyLeagueCreator {
    display       : flex;
    flex-direction: column;
    width         : 95%;
    margin        : auto;
    margin-bottom : 16px
}

.MyLeagueCreatorRow1 {
    width          : 100%;
    display        : flex;
    flex-direction : row;
    justify-content: center;
    align-items    : center
}

.MyLeagueCreatorGameCupImg {
    width : 128px;
    height: 128px
}

.MyLeagueCreatorRow2 {
    font-size      : 36px;
    font-weight    : 700;
    font-family    : "EB Garamond";
    width          : 100%;
    display        : flex;
    flex-direction : row;
    justify-content: center;
    align-items    : center
}

.MyLeagueCreatorRow2 {
    margin-top: 4px
}

.MyLeagueRow1 {
    display        : flex;
    flex-direction : row;
    justify-content: space-between;
    align-items    : center;
    height         : 54px
}

@media (min-width:768px) {
    .MyLeagueRow2 {
        display        : flex;
        flex-direction : row;
        justify-content: start;
        width          : 100%;
        padding        : 0 0 0 20px
    }
}

@media (max-width:768px) {
    .MyLeagueRow2 {
        display        : flex;
        flex-direction : row;
        justify-content: start;
        width          : 96%;
        padding        : 0 0 0 20px
    }
}

.MyLeagueRow3 {
    display        : flex;
    flex-direction : row;
    justify-content: space-between;
    align-items    : center;
    width          : 100%;
    height         : 54px
}

.ProgressBarContainer {
    width          : 100%;
    display        : flex;
    flex-direction : row;
    justify-content: start;
    align-items    : center
}

@media (max-width:980px) {
    .BoxProgress {
        width      : 100%;
        height     : 18px;
        margin-left: -8px
    }
}

@media (min-width:980px) {
    .BoxProgress {
        width      : 100%;
        height     : 18px;
        margin-left: -8px
    }
}

.MyLeagueInner {
    display       : flex;
    flex-direction: row;
    align-items   : center;
    height        : 60px
}

.MyLeagueInner1 {
    display       : flex;
    flex-direction: row;
    align-items   : end;
    height        : 60px;
    margin-bottom : 15px
}

.GameLeagueRate {
    color              : #fff;
    width              : 24px;
    height             : 18px;
    display            : flex;
    flex-direction     : row;
    justify-content    : center;
    align-items        : center;
    font-size          : 16px;
    border-radius      : 2px;
    font-family        : "EB Garamond", serif;
    font-optical-sizing: auto;
    font-style         : normal;
    z-index            : 1
}

.GameLeagueName {
    font-family: "EB Garamond", serif;
    font-size  : 24px;
    font-weight: 700
}

.GameNeddForJoin {
    font-size  : 12px;
    color      : #616161;
    font-family: Product Sans !important;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.RewardNumber {
    padding-left   : 20px;
    display        : flex;
    flex-direction : row;
    justify-content: start;
    align-items    : center
}

.RewardNumberTitle {
    font-size  : 14px;
    color      : #616161;
    font-weight: 400;
    font-family: Product Sans !important
}

.CoinReward {
    width       : 18px;
    height      : 18px;
    margin-right: 2px
}

.StarReward {
    width : 18px;
    height: 18px
}

.RewardNumberCoinNumber {
    font-size  : 16px;
    color      : #212121;
    margin-left: 2px;
    font-weight: 700;
    font-family: Product Sans !important
}

.AllRewardNumber {
    display        : flex;
    flex-direction : row;
    justify-content: center;
    align-items    : center;
    color          : #1976D2;
    font-weight    : 700;
    font-family    : Product Sans !important;
    font-size      : 14px !important;
    height         : 36px;
    width          : 119px
}

.AllRewardNumber:hover {
    background: #E3F2FD
}

.AllRewardNumberImg {
    width       : 20px;
    height      : 20px;
    margin-right: 8px
}

.MyLeagueRow4 {
    display           : flex;
    flex-direction    : column;
    justify-content   : center;
    align-items       : center;
    width             : 100%;
    /* border         : 2px solid #BF8970; */
    border-radius     : 8px
}

.MyLeagueRow4Title {
    display        : flex;
    flex-direction : row;
    align-items    : center;
    justify-content: space-between;
    width          : 100%;
    height         : 56px;
    margin-bottom  : 2px;
    border-radius  : 12px
}

@media (min-width:980px) {
    .MyLeagueRow4Items {
        width                     : 100%;
        border-bottom-left-radius : 10px;
        border-bottom-right-radius: 10px;
        padding-top               : 19px;
        height                    : fit-content;
        border                    : 2px solid #BF8970;
        min-height                : 50dvh;
        border-radius             : 8px
    }
}

@media (max-width:980px) {
    .MyLeagueRow4Items {
        width                     : 100%;
        border-bottom-left-radius : 10px;
        border-bottom-right-radius: 10px;
        padding-top               : 19px;
        height                    : fit-content;
        border                    : 2px solid #BF8970;
        min-height                : 50dvh;
        border-radius             : 8px
    }
}

.MyLeagueRow4Item {
    display           : flex;
    flex-direction    : row;
    width             : 95%;
    justify-content   : space-between;
    align-items       : start;
    margin            : auto;
    height            : 19px;
    /* border         : 2px solid #BF8970; */
    margin-bottom     : 32px;
}

.MyLeagueRow4TitleRank {
    display        : flex;
    flex-direction : row;
    justify-content: center;
    align-items    : center
}

.MyLeagueRow4TitleRankNumber {
    margin-left: 8px;
    font-weight: 700;
    font-family: Product Sans !important;
    font-size  : 14px;
    text-align : center;
}

.MyLeagueRow4TitleRankUser {
    margin-left: 8px;
    font-weight: 700;
    font-family: Product Sans !important;
    font-size  : 16px
}

.MyLeagueRow4TitleRankIsUser {
    margin-left  : 8px;
    font-weight  : 400;
    font-family  : Product Sans !important;
    font-size    : 12px;
    border-radius: 30px;
    padding      : 3px 6px
}

.MyLeagueRow4TitleJoinRank {
    /* margin-right: 8px; */
    font-weight : 700;
    font-family : Product Sans !important;
    font-size   : 14px;
    margin-right: 10px;
    margin-left : 10px;
    font-weight : 700;
    font-family : Product Sans !important;
    font-size   : 14px;
    min-width   : 70px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.MyLeagueRow4ItemInner {
    font-weight: 500;
    font-family: Product Sans !important;
    font-size  : 14px
}

.MyLeagueRow4ItemInnerNumber {
    width      : 30px;
    height     : 24px;
    font-weight: 700;
    font-family: Product Sans !important;
    font-size  : 14px;
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
}

.LastUpdate {
    margin-left  : 10px;
    margin-bottom: 8px;
    font-weight  : 400;
    font-family  : Product Sans !important;
    font-size    : 12px;
    color        : #212121
}

.ImageProfileUser {
    margin-right : 12px;
    width        : 32px;
    height       : 32px;
    border-radius: 33px;
}


marquee {
    max-width: 95px;
}