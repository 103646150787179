.LoadingContainer {
  width          : 100%;
  height         : 80vh;
  display        : flex;
  flex-direction : row;
  justify-content: center;
  align-items    : center;
}

.LoadingContainerminiJoin {
  width             : 100%;
  /* height         : 80vh; */
  height            : 30px;
  display           : flex;
  flex-direction    : row;
  justify-content   : center;
  align-items       : center;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}


@-webkit-keyframes rotate {
  from {
    -webkit-transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
  }
}

.load {
  width                            : 40px;
  height                           : 40px;
  margin                           : 1px auto 0;
  border                           : solid 4px #1976d2;
  border-radius                    : 50%;
  border-right-color               : transparent;
  border-bottom-color              : transparent;
  -webkit-transition               : all 0.5s ease-in;
  -webkit-animation-name           : rotate;
  -webkit-animation-duration       : 1.0s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  transition                       : all 0.5s ease-in;
  animation-name                   : rotate;
  animation-duration               : 1.0s;
  animation-iteration-count        : infinite;
  animation-timing-function        : linear;
}

.loadminijoin {
  /* width                            : 24px;
  height                           : 24px; */

  padding                             : 12px;
  display                             : flex;
  flex-direction                      : row;
  justify-content                     : center;
  align-items                         : center;
  margin                              : 1px auto 0;
  color                               : #fff;
  border                              : solid 2px #fff;
  border-radius                       : 100%;
  /* border-right-color               : #1976d2; */
  border-bottom-color                 : transparent;
  -webkit-transition                  : all 0.5s ease-in;
  -webkit-animation-name              : rotate;
  -webkit-animation-duration          : 1.0s;
  -webkit-animation-iteration-count   : infinite;
  -webkit-animation-timing-function   : linear;
  transition                          : all 0.5s ease-in;
  animation-name                      : rotate;
  animation-duration                  : 1.0s;
  animation-iteration-count           : infinite;
  animation-timing-function           : linear;
}

.LoaderContainer {
  width : 24px;
  height: 32px;
  margin: auto;
}

.loadminiBlue {
  /* width : 20px;
  height: 20px; */

  padding                             : 10px;
  display                             : flex;
  flex-direction                      : row;
  justify-content                     : center;
  align-items                         : center;
  margin                              : 1px auto 0;
  color                               : #fff;
  border                              : solid 3px #1565C0;
  border-radius                       : 100%;
  /* border-right-color               : #1976d2; */
  border-bottom-color                 : transparent;
  -webkit-transition                  : all 0.5s ease-in;
  -webkit-animation-name              : rotate;
  -webkit-animation-duration          : 1.0s;
  -webkit-animation-iteration-count   : infinite;
  -webkit-animation-timing-function   : linear;
  transition                          : all 0.5s ease-in;
  animation-name                      : rotate;
  animation-duration                  : 1.0s;
  animation-iteration-count           : infinite;
  animation-timing-function           : linear;
}