.Header {
    position  : fixed;
    top       : 0;
    width     : 100%;
    height    : 56px;
    background: #fff;
    z-index   : 1
}

.CoinHeader {
    width          : 90%;
    height         : 56px;
    display        : flex;
    flex-direction : row;
    justify-content: space-between;
    align-items    : center;
    margin         : auto;
    animation      : fadeIn .5s ease forwards;
    max-width: 768px;
}

.HeaderLogo {
    font-family: Product Sans;
    font-weight: 700;
    font-size  : 24px;
    color      : #212121
}

.HeaderCoin {
    font-weight   : 700;
    font-size     : 14px;
    font-family   : Product Sans;
    display       : flex;
    flex-direction: row;
    align-items   : center;
    height: 40px;
    padding: 0 4px;
}

.HeaderCoinImgContainer {
    display        : flex;
    flex-direction : row;
    justify-content: center;
    align-items    : center;
    margin-left    : 8px
}

@keyframes fadeIn {
    from {
        opacity: 0
    }

    to {
        opacity: 1
    }
}

.HeaderCoinImg1 {
    margin: 0 2px 0 2px
}

.CoinHeaderImageLoader {
    width                            : 24px;
    height                           : 24px;
    margin                           : 0 auto 0;
    border                           : solid 2px #FFB01E;
    border-radius                    : 50%;
    border-right-color               : #fff0;
    border-bottom-color              : #fff0;
    -webkit-transition               : all 0.5s ease-in;
    -webkit-animation-name           : rotate;
    -webkit-animation-duration       : 1.0s;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    transition                       : all 0.5s ease-in;
    animation-name                   : rotate;
    animation-duration               : 1.0s;
    animation-iteration-count        : infinite;
    animation-timing-function        : linear;
    position                         : absolute
}