.Home {
    width          : 100%;
    height         : 100%;
    position       : relative;
    display        : flex;
    flex-direction : column;
    justify-content: flex-start;
    align-items    : center
}

.NavContainer {
    width     : 100%;
    position  : fixed;
    bottom    : 0;
    background: #EFF7FE
}

.Content {
    padding-top   : 56px;
    padding-bottom: 88px;
    width         : 100%
}

.MemberPackage {
    width    : 94%;
    max-width: 768px;
    margin   : auto
}

.MemberPackageTitle {
    font-size      : 16px;
    font-weight    : 700;
    font-family    : Product Sans;
    margin-top     : 32px;
    width          : 98%;
    height         : 36px;
    display        : flex;
    flex-direction : row;
    justify-content: flex-start;
    align-items    : center;
    max-width      : 768px
}

.MemberPackageTitleWithoutFlag {
    font-size      : 16px;
    font-weight    : 700;
    font-family    : Product Sans;
    margin-top     : 74px;
    width          : 98%;
    height         : 36px;
    display        : flex;
    flex-direction : row;
    justify-content: flex-start;
    align-items    : center;
    max-width      : 768px
}

.MemberPackageItems {
    display        : flex;
    flex-direction : column;
    justify-content: center;
    align-items    : center
}

.MemberPackageItem {
    width          : 100%;
    height         : 72px;
    display        : flex;
    flex-direction : row;
    justify-content: flex-start;
    align-items    : center;
    animation      : fadeIn .5s ease forwards
}

@keyframes fadeIn {
    from {
        opacity: 0
    }

    to {
        opacity: 1
    }
}

.ViewPackage {
    width    : 94%;
    max-width: 768px;
    margin   : auto
}

.ViewPackageTitle {
    font-size      : 16px;
    font-weight    : 700;
    font-family    : Product Sans;
    margin-top     : 32px;
    width          : 98%;
    height         : 36px;
    display        : flex;
    flex-direction : row;
    justify-content: flex-start;
    align-items    : center;
    max-width      : 768px
}

.ViewPackageItems {
    display        : flex;
    flex-direction : column;
    justify-content: center;
    align-items    : center
}

.TabHomeContainer {
    background: #fff;
    position  : fixed;
    top       : 56px;
    width     : 100%;
    z-index   : 1
}

.TabsHomeHeader {
    width          : 100%;
    display        : flex;
    flex-direction : row;
    justify-content: center;
    align-items    : center;
    border-bottom  : 1px solid #E7E7E7;
    margin         : auto;
    height         : 48px;
    max-width      : 768px
}

.TabsHomeHeaderItem {
    width          : 50%;
    display        : flex;
    flex-direction : row;
    justify-content: center;
    cursor         : pointer
}

@media (min-width:768px) {
    .TabsHomeHeaderItem:hover {
        background: #F5F5F5
    }
}

.TabHomeSpanDisable {
    display        : flex;
    flex-direction : column;
    justify-content: end;
    align-items    : center;
    height         : 48px;
    width          : fit-content;
    color          : #616161;
    font-family    : Product Sans;
    font-size      : 14px;
    font-style     : normal;
    font-weight    : 500;
    line-height    : normal;
    height         : 48px
}

.TabHomeSpanActive {
    color          : #1976D2;
    display        : flex;
    flex-direction : column;
    justify-content: end;
    align-items    : center;
    height         : 48px;
    width          : fit-content;
    font-family    : Product Sans;
    font-size      : 14px;
    font-style     : normal;
    font-weight    : 500;
    line-height    : normal;
    height         : 48px
}

.TabHomeActivateBorder {
    height                 : 3px;
    width                  : 100%;
    background             : #1976D2;
    margin-top             : 12px;
    border-top-left-radius : 16px;
    border-top-right-radius: 16px
}

.TabHomeDeActivateBorder {
    height       : 3px;
    width        : 100%;
    /* background: #EFF7FE; */
    background   : #fff;
    margin-top   : 12px
}

.TabHomeTextImg {
    display        : flex;
    flex-direction : row;
    align-items    : center;
    justify-content: center
}

.TabHomeTextImgIcon {
    margin-right: 8px;
    width       : 24px;
    height      : 24px
}

.FooterContent {
    font-family    : Product Sans;
    display        : flex;
    flex-direction : column;
    justify-content: center;
    align-items    : center;
    width          : 100%;
    max-width      : 768px;
    cursor         : default;
    padding-bottom : 80px
}

.FooterContentTitle {
    font-size  : 12px;
    font-weight: 400;
    font-family: Product Sans
}

.FooterContentItems {
    display        : flex;
    flex-direction : row;
    justify-content: center;
    align-items    : center;
    width          : 100%;
    height         : 63px;
    margin-top     : 25px;
    margin-bottom  : 72px
}

.FooterContentItem {
    display        : flex;
    flex-direction : column;
    justify-content: center;
    align-items    : center;
    width          : 33%;
    height         : 63px;
    font-size      : 10px;
    font-weight    : 400;
    font-family    : Product Sans
}

.Bestquality {
    border-left : 1px solid #E0E0E0;
    border-right: 1px solid #E0E0E0
}

.FooterContentItemPic {
    margin-bottom: 8px
}

.UnsuccessfulPaymentValue {
    color          : #212121;
    font-family    : Product Sans !important;
    font-weight    : 700;
    font-size      : 16px;
    display        : flex;
    flex-direction : row;
    align-items    : center;
    justify-content: center;
    width          : 100%;
    text-align     : center
}

.Insufficient {
    color          : #212121;
    font-family    : Product Sans !important;
    font-weight    : 700;
    font-size      : 20px;
    display        : flex;
    flex-direction : row;
    align-items    : center;
    justify-content: center;
    width          : 100%;
    text-align     : center;
    margin-top     : 8px
}

.UnsuccessfulPaymentImg {
    margin-right: 8px
}

.successfulPaymentItem {
    width          : 100%;
    display        : flex;
    flex-direction : column;
    justify-content: space-between;
    align-items    : center;
    height         : 72px
}

.SuccessfulPaymentText {
    margin-top    : 16px;
    color         : #212121;
    font-family   : Product Sans !important;
    font-weight   : 400;
    font-size     : 14px;
    display       : flex;
    flex-direction: row;
    align-items   : center;
}

.pointInBottomSheet {
    display           : flex;
    flex-direction    : row;
    /* justify-content: center; */
    align-items       : center;
    margin-bottom     : 4px;

}