.AllReward {
    width          : 100%;
    display        : flex;
    flex-direction : column;
    justify-content: start;
    align-items    : start;
    height         : 100%;
    margin         : auto;
    overflow       : auto
}

.AllRewardHeader {
    width          : 100%;
    height         : 64px;
    display        : flex;
    flex-direction : row;
    justify-content: space-between;
    align-items    : center;
    margin         : 0 auto;
    animation      : fadeIn .5s ease forwards;
    background     : #fff
}

.AllRewardHeaderInner {
    width          : 90%;
    height         : 56px;
    display        : flex;
    flex-direction : row;
    justify-content: space-between;
    align-items    : center;
    margin         : 0 auto;
    animation      : fadeIn .5s ease forwards;
    font-weight    : 700;
    font-family    : Product Sans !important;
    font-size      : 24px;
    max-width: 768px;
}

.AllRewardHeaderInnerBack {
    display        : flex;
    flex-direction : row;
    justify-content: center;
    align-items    : center;
    font-size      : 20px;
    font-weight    : 500;
    font-family    : Product Sans !important
}

.AllRewardBack {
    display        : flex;
    flex-direction : row;
    justify-content: center;
    align-items    : center;
    width          : 40px;
    height         : 40px;
    border-radius  : 33px;
    margin-right   : 16px
}

.AllRewardBack:hover {
    background: #F5F5F5
}

.AllRewardBack img {
    width : 24px;
    height: 24px
}

.AllRewardContent {
    width          : 100%;
    display        : flex;
    flex-direction : column;
    justify-content: start;
    align-items    : center;
    max-width      : 768px;
    margin         : 0 auto;
    overflow       : scroll
}

.AllRewardItems {
    width     : 100%;
    margin-top: 24px
}

.BronzeReward {
    display        : flex;
    flex-direction : column;
    justify-content: center;
    align-items    : center;
    width          : 100%;
    margin-bottom  : 26px
}

.BronzeTitleitem {
    display        : flex;
    flex-direction : row;
    justify-content: start;
    align-items    : center;
    width          : 92%;
    position       : relative
}

.BronzeTitleitemText {
    background     : #BF8970;
    width          : 90%;
    height         : 48px;
    display        : flex;
    flex-direction : row;
    justify-content: start;
    align-items    : center;
    border-radius  : 8px;
    padding-left   : 24px;
    font-family    : "EB Garamond", serif;
    font-size      : 20px;
    font-weight    : 700
}

.BronzeTitleitemCup {
    width           : 72px;
    height          : 72px;
    border-radius   : 100%;
    border          : 2px solid #BF8970;
    background-color: #FFEDE6;
    display         : flex;
    flex-direction  : row;
    justify-content : center;
    align-items     : center;
    position        : absolute;
    right           : 0;
    z-index         : 1
}

.BronzeBodyitem {
    display        : flex;
    flex-direction : row;
    justify-content: space-between;
    align-items    : self-start;
    width          : 92%;
    position       : relative
}

.BronzeBodyItemList {
    display        : flex;
    flex-direction : column;
    align-items    : center;
    justify-content: center;
    width          : 70%;
    background     : #FFEDE6;
    margin-top     : 24px;
    height         : 175px;
    padding        : .5rem;
    border-radius  : 8px
}

.LineContainer {
    position       : absolute;
    right          : 38px;
    top            : 14px;
    display        : flex;
    flex-direction : column;
    justify-content: start;
    align-items    : center;
    height         : 100%
}

.VerticalLineFirst {
    padding-top: 30px !important
}

.VerticalLineLast {
    padding-bottom: 60px !important
}

.CreatorLine {
    display: none !important
}

.CreatorLineEmpty {
    width         : 0;
    display       : flex;
    flex-direction: column;
    align-items   : center;
    z-index       : 0 !important;
    height        : 9% !important;
    padding-top   : 40px !important
}

.CreatorLineFull {
    display       : flex;
    flex-direction: column;
    align-items   : center;
    z-index       : 0 !important;
    height        : 10% !important;
    padding-top   : 2px !important
}

.VerticalLineCenter {
    padding-top: 36px !important
}

.VerticalLine {
    width         : 0;
    display       : flex;
    flex-direction: column;
    align-items   : center;
    border        : 1px solid #fff0;
    z-index       : 0 !important;
    height        : 10%
}

.VerticalLineEmpty {
    width         : 0;
    display       : flex;
    flex-direction: column;
    align-items   : center;
    border        : 1px dashed #BF8970;
    z-index       : 0 !important;
    height        : 100%
}

.VerticalLineFinish {
    width         : 0;
    display       : flex;
    flex-direction: column;
    align-items   : center;
    border        : 1px dashed #BF8970;
    z-index       : 0 !important;
    height        : 12%
}

.VerticalLineFinishFilled {
    width         : 0;
    display       : flex;
    flex-direction: column;
    align-items   : center;
    border        : 1px solid #BF8970;
    z-index       : 0 !important;
    height        : 12%
}

.VerticalLineFinishDisable {
    width         : 0;
    display       : flex;
    flex-direction: column;
    align-items   : center;
    z-index       : 0 !important;
    height        : 12%
}

.VerticalLineItem {
    width          : 20px;
    height         : 20px;
    background     : #BF8970;
    border-radius  : 100%;
    display        : flex;
    flex-direction : row;
    align-items    : center;
    justify-content: center
}

.VerticalLineItemEmpty {
    width          : 20px;
    height         : 20px;
    background     : #fff;
    border-radius  : 100%;
    display        : flex;
    flex-direction : row;
    align-items    : center;
    justify-content: center;
    border         : 1px solid #BF8970
}

.LastPadding {
    padding       : 40px 0 0 0 !important;
    width         : 0;
    display       : flex;
    flex-direction: column;
    align-items   : center;
    z-index       : 0 !important;
    height        : 9% !important
}

.LastPaddForInner {
    padding-bottom: 19px !important
}

.LastPaddForInnerActive {
    padding-bottom: 1px !important
}

.VerticalLineItemIcon {
    width : 20px !important;
    height: 20px !important;
    color : #212121
}

.BronzeBodyItemListIn {
    display        : flex;
    flex-direction : row;
    justify-content: space-between;
    align-items    : center;
    width          : 95%;
    margin-bottom  : 16px
}

.BronzeBodyItemListIn1 {
    display        : flex;
    flex-direction : column;
    justify-content: center;
    align-items    : start
}

.BronzeBodyItemListIn1Title {
    color      : #321203;
    font-weight: 700;
    font-family: Product Sans !important;
    font-size  : 14px
}

.BronzeBodyItemListIn1Des {
    color      : #4B2714;
    font-weight: 400;
    font-family: Product Sans !important;
    font-size  : 12px;
    margin-top : 4px;display: flex;
}

.BronzeBodyItemListIn2 {
    color          : #321203;
    font-family    : Product Sans !important;
    font-size      : 14px;
    font-weight    : 400;
    display        : flex;
    flex-direction : row;
    justify-content: center;
    align-items    : center
}

.RewardCoin,
.RewardStar {
    margin: 0 3px
}

.DeactiveCreatorI {
    display        : flex;
    flex-direction : row;
    justify-content: center;
    width          : 100%;
    align-items    : center;
    color          : #616161;
    font-weight    : 700;
    font-size      : 14px;
    font-family    : Product Sans !important
}

.ActiveCreatorI {
    display        : flex;
    flex-direction : row;
    justify-content: center;
    width          : 100%;
    align-items    : center;
    font-weight    : 700;
    font-size      : 14px;
    font-family    : Product Sans !important;
    color          : #321203
}